import Icon from '@material-ui/core/Icon';
import axios from 'axios';
import moment from 'moment';
import React from 'react';
import {
    AIR_TABLE_TOKEN_IDENTIFIER,
    ANNEXED_B,
    ANNEXED_D,
    APPROVAL,
    TECHNICAL_SHEET,
    USER_GUIDE,
    VERBAL_PROCESS,
    WARRANTY_CERTIFICATE,
} from '../services/utils/CONST';
import { extractIdFromURI } from '../services/utils/ids';
import { Avatar } from '../services/utils/types';
const FILE_SYSTEM_MAPPER_ENTRYPOINT = process.env.NEXT_PUBLIC_FILE_SYSTEM_MAPPER_ENTRYPOINT;

export const capitalizeString = (value: string) => value.charAt(0).toUpperCase() + value.slice(1);

const convertIconName = (iconName: string) => {
    let data = iconName.match(/[A-Z][a-z]+/g);
    let newData = '';
    data &&
        data.map((item) => {
            newData = `${newData}${item.toLowerCase()}_`;
        });
    return newData.slice(0, newData.length - 1);
};

export const extractIconAndName = (iconId: string) => {
    const temp = iconId.split('/');
    return temp[2].split(';');
};

export const resolveIcon = (iconId: string | null, defaultIcon: any, props?: any) => {
    const materialIconRegularExpression = /@material-ui\/icons\/.+/;
    if (iconId && materialIconRegularExpression.test(iconId)) {
        const dataName = extractIconAndName(iconId);
        const iconName = dataName[0];
        const color = dataName.length > 1 ? dataName[1] : null;
        return React.createElement(
            Icon,
            {
                ...(props ? props : {}),
            },
            convertIconName(iconName)
        );
    } else {
        return React.createElement(Icon, { ...(props ? props : {}) }, convertIconName(defaultIcon));
    }
};

export const renderDangerousText = (text: string, props: any = {}, el: string = 'span') => {
    const styles = {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        ...(props.hasOwnProperty('style') ? props['style'] : {}),
    };
    const mStyles = props.hasOwnProperty('style') && props['style'] ? props['style'] : styles;
    return React.createElement(el, {
        ...props,
        style: {
            ...mStyles,
        },
        dangerouslySetInnerHTML: { __html: text },
    });
};

export const toFormatDate = (stringDate: string, momentFormat = 'DD/MM/YYYY  HH:mm:ss') => {
    const date = moment(stringDate);
    return date.format(momentFormat);
};

export const getPathProcess = (startNode: any) => {
    let nodesTemp = [];
    let result = [];
    let visited: any = {};
    let resultVisited: any = {};

    if (
        (startNode.hasOwnProperty('isStartNodeBridgeToStartProcess') &&
            startNode.isStartNodeBridgeToStartProcess === true) ||
        (startNode.hasOwnProperty('name') && startNode.name.length === 0)
    ) {
        startNode =
            Array.isArray(startNode.nextNodes) && startNode.nextNodes.length > 0 ? startNode.nextNodes[0] : null;
    }

    if (startNode && startNode.hasOwnProperty('nextNodes')) {
        result.push(startNode);
        nodesTemp.push(startNode);
    }

    while (nodesTemp.length > 0) {
        let node: any = nodesTemp.shift();

        if (node.id in visited) {
            continue;
        }
        visited[node.id] = true;
        resultVisited[node.id] = true;

        let nextNodes = node.nextNodes && node.nextNodes.length > 0 ? node.nextNodes : null;

        if (nextNodes) {
            nextNodes.forEach((nextN: any) => {
                nodesTemp.push(nextN);
                if (!(nextN.id in resultVisited)) {
                    resultVisited[nextN.id] = true;
                    result.push(nextN);
                }
            });
        }
    }

    return result;
};

export const dataURLtoFile = (dataUrl: any, filename: any) => {
    let arr = dataUrl.split(',');
    let mime = arr[0].match(/:(.*?);/)[1];
    let bstr = atob(arr[1]);
    let n = bstr.length;
    let u8arr = new Uint8Array(n);
    while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
};

export const getName = (nameWithPrefix: string) => {
    if (nameWithPrefix) return nameWithPrefix.split('-prefix-').reverse()[0];
    return nameWithPrefix;
};

export const getAirTableToken = (avatar: Avatar | null) => {
    if (!avatar) return '';

    const identifiers = avatar?.company?.identifiers;
    let token = '';
    if (identifiers) {
        identifiers.map((id) => {
            if (id.includes(AIR_TABLE_TOKEN_IDENTIFIER)) {
                token = id.split(':')[1];
            }
        });
    }
    return token;
};

export const getCurrentPosition = (callback: any, errorCallback?: any) => {
    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
            (position: any) => {
                callback({
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                });
            },
            (error) => {
                if (errorCallback) errorCallback(error);
            }
        );
    }
};

export const getFileSystemMapperId = (
    tokenWithRoles: string,
    tagName: string,
    lastParam: { value: string; type: 'avatar' | 'groupMeta' | 'meta' },
    successCallback: (id: string | null, fileName: string | null) => void,
    failureCallback: () => void
) => {
    let lastFilter = `parent.parent.name=sc:folder-`;

    switch (lastParam.type) {
        case 'groupMeta': {
            lastFilter = `${lastFilter}group_metadata_avatar_type:${lastParam.value}`;
            break;
        }
        case 'meta': {
            lastFilter = `${lastFilter}metadata_avatar_type:${lastParam.value}`;
            break;
        }
        default: {
            lastFilter = `${lastFilter}avatar:${lastParam.value}`;
            break;
        }
    }

    axios
        .get(
            `${FILE_SYSTEM_MAPPER_ENTRYPOINT}/smartConnectView/file_system_mappers?lvl=5&tags.name=${tagName}&isFolder=false&parent.name=system&${lastFilter}`,
            {
                headers: {
                    Authorization: `Bearer ${tokenWithRoles}`,
                },
            }
        )
        .then(({ data }) => {
            if (data['hydra:member'].length > 0) {
                successCallback(extractIdFromURI(data['hydra:member'][0]['@id']), data['hydra:member'][0].name);
            } else {
                successCallback(null, null);
            }
        })
        .catch(() => {
            failureCallback();
        });
};

export const getFile = (
    tokenWithRoles: string,
    id: string,
    successCallback: (data: any) => void,
    failureCallback: () => void
) => {
    axios
        .get(`${FILE_SYSTEM_MAPPER_ENTRYPOINT}/smartConnectView/file_system_mappers/${id}/download`, {
            headers: {
                Authorization: `Bearer ${tokenWithRoles}`,
            },
            responseType: 'blob',
        })
        .then(({ data }) => {
            successCallback(data);
        })
        .catch(() => {
            failureCallback();
        });
};

export const hasRole = (rolesToCheck: string, roles: string[]) => roles.includes(rolesToCheck);
